export async function loadCarousel(carouselElement) {
    const {
        registerCarousel,
    } = await import('../../../abstracts/carousel/carousel');

    registerCarousel(carouselElement);
}

export function registerCarouselElements() {
    const intersectorOptions = {
        rootMargin: '0px',
        threshold: [0],
    };

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                loadCarousel(entry.target);
                observer.unobserve(entry.target); // Stop observing once loaded
            }
        });
    }, intersectorOptions);

    const isMobile = window.matchMedia('(max-width: 576)').matches;

    if (!isMobile) {
        document.querySelectorAll('.carousel').forEach((carouselElement) => {
            if (!carouselElement.classList.contains('strapped')) {
                observer.observe(carouselElement);
            }
        });
    }
}
