import docReady from '../../assets/js/docReady';
import { registerCarouselElements } from '../../assets/js/helpers/carousel';

function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}

docReady(() => {
    registerCarouselElements();

    const debouncedRegisterCarouselElements = debounce(registerCarouselElements, 250);
    window.addEventListener('resize', debouncedRegisterCarouselElements);
});
